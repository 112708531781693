import PropTypes from "prop-types";
import React, {
  Component,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import { Button, Dropdown, Modal } from "semantic-ui-react";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import ExportForm from "./forms/ExportForm";
import ImportForm from "./forms/ImportForm";
import LegacyImportForm from "./forms/LegacyImportForm";

import CaseDataImportForm from "../../../components/forms/CaseDataImportForm";
import CONSTANTS from "../../../constants/Constants";
import "./Modals.scoped.scss";

const { EXPORT_TYPES, IMPORT_TYPES } = CONSTANTS;

class DeleteJobModal extends Component {
  static propTypes = {
    onConfirmDelete: PropTypes.func.isRequired,
    menuTrigger: PropTypes.bool,
    noIcon: PropTypes.bool,
  };

  render() {
    return (
      <ConfirmationModal
        actionDescription="Delete"
        buttonColor="grey"
        onConfirm={this.props.onConfirmDelete}
        menuTrigger={this.props.menuTrigger}
        icon={this.props.noIcon ? null : "trash"}
        warning
      >
        <p>Are you sure you want to delete this Job Process?</p>
      </ConfirmationModal>
    );
  }
}

const ImportModal = forwardRef(({ importType, fetchData }, ref) => {
  const [open, setOpen] = useState(false);
  const [dataJobId, setDataJobId] = useState(null);

  useImperativeHandle(ref, () => ({
    open: handleOpen,
  }));

  const handleOpen = dataJobId => {
    setDataJobId(dataJobId);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleSuccess = () => {
    fetchData();
    handleClose();
  };

  return (
    <Modal
      open={open}
      closeIcon
      onClose={handleClose}
      onFocus={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      closeOnDimmerClick={false}
      closeOnEscape={false}
      size={
        importType === CONSTANTS.IMPORT_TYPES.CASE_DATA ? "small" : "fullscreen"
      }
    >
      <Modal.Header>
        {importType === CONSTANTS.IMPORT_TYPES.CASE_DATA
          ? "Import Case Data"
          : "Import Data"}
      </Modal.Header>
      <Modal.Content>
        {importType === CONSTANTS.IMPORT_TYPES.CASE_DATA ? (
          <CaseDataImportForm
            onSuccess={handleSuccess}
            onClose={handleClose}
            refreshTable={fetchData}
            dataJobId={dataJobId}
          />
        ) : (
          <ImportForm
            onSuccess={handleSuccess}
            onClose={handleClose}
            refreshTable={fetchData}
            dataJobId={dataJobId}
          />
        )}
      </Modal.Content>
    </Modal>
  );
});

const ImportModalTrigger = ({ dropdown = false, status, ...buttonProps }) => {
  const getDropdownButtonText = () => {
    let msg;
    switch (status) {
      case CONSTANTS.DATA_JOB_STATUSES.FAILED:
      case CONSTANTS.DATA_JOB_STATUSES.ABORTED:
      case CONSTANTS.DATA_JOB_STATUSES.COMPLETE:
        msg = "View Report";
        break;
      case CONSTANTS.DATA_JOB_STATUSES.SCHEDULED:
      case CONSTANTS.DATA_JOB_STATUSES.IN_PROGRESS:
        msg = "View Progress";
        break;
      case CONSTANTS.DATA_JOB_STATUSES.READY:
        msg = "Confirm Import";
        break;
    }
    return msg;
  };

  return dropdown ? (
    <Dropdown.Item
      icon="clone"
      content={getDropdownButtonText()}
      {...buttonProps}
    />
  ) : (
    <Button className="item-adder" {...buttonProps} />
  );
};
ImportModal.Trigger = ImportModalTrigger;

function ImportDataModal({ onDone, fetchData, campaignId }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleSuccess = () => {
    handleClose();
    fetchData();
    onDone();
  };

  return (
    <Modal
      trigger={<Button content="Import Data" onClick={handleOpen} />}
      open={open}
      closeIcon
      onClose={handleClose}
      onFocus={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      closeOnDimmerClick={false}
      closeOnEscape={false}
    >
      <Modal.Header>Import Data</Modal.Header>
      <Modal.Content>
        <LegacyImportForm
          campaignId={campaignId}
          onSuccess={handleSuccess}
          onClose={handleClose}
          refreshTable={fetchData}
        />
      </Modal.Content>
    </Modal>
  );
}

ImportDataModal.propTypes = {
  campaignId: PropTypes.number,
};

function ImportAuditLogModal({ onDone, fetchData }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleSuccess = () => {
    handleClose();
    fetchData();
    onDone();
  };

  return (
    <Modal
      trigger={<Button content="Import Audit Logs" onClick={handleOpen} />}
      open={open}
      onClose={handleClose}
      closeOnDimmerClick={false}
      onFocus={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      closeIcon
    >
      <Modal.Header>Import Audit Logs</Modal.Header>
      <Modal.Content>
        <LegacyImportForm
          onSuccess={handleSuccess}
          onClose={handleClose}
          type={IMPORT_TYPES.AUDIT_LOGS}
          refreshTable={fetchData}
        />
      </Modal.Content>
    </Modal>
  );
}

function ExportContactsModal({ onDone, fetchData }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleSuccess = () => {
    handleClose();
    fetchData();
    onDone();
  };

  return (
    <Modal
      trigger={<Button content="Export Contacts" onClick={handleOpen} />}
      open={open}
      closeIcon
      onClose={handleClose}
      closeOnDimmerClick={false}
      onFocus={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
    >
      <Modal.Header>Export Contacts</Modal.Header>
      <Modal.Content>
        <ExportForm
          onSuccess={handleSuccess}
          onClose={handleClose}
          type={EXPORT_TYPES.CONTACTS}
          refreshTable={fetchData}
        />
      </Modal.Content>
    </Modal>
  );
}

function ExportTargetsModal({ onDone, fetchData }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleSuccess = () => {
    handleClose();
    fetchData();
    onDone();
  };

  return (
    <Modal
      trigger={
        <Button content="Export Campaign Targets" onClick={handleOpen} />
      }
      open={open}
      closeIcon
      onClose={handleClose}
      closeOnDimmerClick={false}
      onFocus={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
    >
      <Modal.Header>Export Campaign Targets</Modal.Header>
      <Modal.Content>
        <ExportForm
          onSuccess={handleSuccess}
          onClose={handleClose}
          type={EXPORT_TYPES.CAMPAIGN_TARGETS}
          refreshTable={fetchData}
        />
      </Modal.Content>
    </Modal>
  );
}

function ExportAuditLogModal({ onDone, fetchData }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleSuccess = () => {
    handleClose();
    fetchData();
    onDone();
  };

  return (
    <Modal
      trigger={<Button content="Export Audit Logs" onClick={handleOpen} />}
      open={open}
      closeIcon
      onClose={handleClose}
      closeOnDimmerClick={false}
      onFocus={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
    >
      <Modal.Header>Export Audit Logs</Modal.Header>
      <Modal.Content>
        <ExportForm
          onSuccess={handleSuccess}
          onClose={handleClose}
          type={EXPORT_TYPES.AUDIT_LOGS}
          refreshTable={fetchData}
        />
      </Modal.Content>
    </Modal>
  );
}

export {
  DeleteJobModal,
  ExportAuditLogModal,
  ExportContactsModal,
  ExportTargetsModal,
  ImportAuditLogModal,
  ImportDataModal,
  ImportModal,
};
