import React, { useState } from "react";
import axios from "axios";
import { Modal, Button, Message, Dropdown } from "semantic-ui-react";
import { LoadingMessage } from "components/helpers";
import { readEml } from "eml-parse-js";
import CampaignService from "services/Campaign";

const ViewEmailModal = ({ emailId, emailUrl, dropdown = false }) => {
  const [htmlContent, setHtmlContent] = useState(null);
  const [emailError, setEmailError] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    if (emailUrl) {
      getEML(emailUrl);
    } else if (emailId) {
      getEmailHTML(emailId);
    }
    setOpen(true);
  };

  const getEML = async emailUrl => {
    const { data } = await axios.get(emailUrl);
    readEml(data, (err, EmlJson) => {
      if (err) {
        setEmailError(true);
      } else {
        setHtmlContent(EmlJson.html);
      }
    });
  };

  const getEmailHTML = async emailId => {
    try {
      const data = await CampaignService.getEmailData(emailId);
      setHtmlContent(data.html_content);
    } catch (e) {
      setEmailError(true);
    }
  };

  return (
    <>
      <Modal
        trigger={
          dropdown ? (
            <Dropdown.Item content="View Email Sent" onClick={handleOpen} />
          ) : (
            <Button
              style={{
                visibility: !!(emailUrl || emailId) ? "visible" : "hidden",
              }}
              basic
              size="tiny"
              content="View Email Sent"
              onClick={handleOpen}
            />
          )
        }
        open={open}
        closeOnDimmerClick={false}
        onClose={() => setOpen(false)}
        closeIcon
      >
        <Modal.Header>Email Content</Modal.Header>
        <Modal.Content>
          {(emailError && (
            <Message
              color="red"
              header="Error"
              content="Could not load email"
            />
          )) ||
            (htmlContent && (
              <div
                dangerouslySetInnerHTML={{
                  __html: htmlContent,
                }}
              />
            )) || <LoadingMessage />}
        </Modal.Content>
      </Modal>
    </>
  );
};

export default ViewEmailModal;
