import React, { Component } from "react";
import {
  Accordion,
  Button,
  Form,
  Grid,
  Icon,
  List,
  Segment,
} from "semantic-ui-react";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import omitBy from "lodash/omitBy";
import sortBy from "lodash/sortBy";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";

import CustomAttachmentForm from "../forms/CustomAttachmentForm";
import EmailableContactsModal from "../components/EmailableContactsModal";
import EmailDeliveryTime from "../forms/EmailDeliveryTime";
import RuvixxForm from "../../../components/RuvixxForm";
import OutreachFiltersForm from "../../../components/OutreachFiltersForm";
import EmailScheduleSendOptionForm from "../forms/EmailScheduleSendOptionForm";

import CampaignService from "../../../services/Campaign";
import CampaignScheduleService from "../../../services/CampaignScheduleService";
import EmailTemplateService from "../../../services/EmailTemplate";
import EmailFooterService from "../../../services/EmailFooters";
import EmailSignatureService from "../../../services/EmailSignatures";
import EntityService from "../../../services/Entity";
import CustomEmailDomainsService from "services/CustomEmailDomains";
import TagService from "../../../services/Tag";
import UploadManager from "../../../components/UploadManager";
import UploadProgressRing from "../../../components/UploadProgressRing";
import SmtpAccountService from "../../../services/SmtpAccounts";
import EmailProviderService from "../../../services/EmailProviders";
import UserService from "../../../services/User";
import CONSTANTS from "../../../constants/Constants";
import { v4 as uuidv4 } from "uuid";
import ConstantContactAccountsService from "../../../services/ConstantContactAccounts";
import Validate from "../../../components/Validate";

class CampaignScheduleForm extends Component {
  static propTypes = {
    campaignId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    newSchedule: PropTypes.bool,
    scheduleId: PropTypes.number,
    isClone: PropTypes.bool,
  };

  static nullOption = {
    key: null,
    value: null,
    text: "None",
  };

  constructor(props) {
    super(props);
    this.uploadManager = undefined;
    this.state = {
      scheduleId: undefined,
      scheduleName: "",
      status: false,
      sendNow: true,
      emailDeliveryTime: null,
      timeZone: "UTC",
      timeZones: [],
      emailTemplateId: undefined,
      conditions: [],
      customAttachments: [],
      info: {},
      campaignStatusOptions: [],
      templates: [],
      emailTemplateOptions: [],
      questionnaires: [],
      files: [],
      entityTagOptions: [],
      callTagOptions: [],
      contactTagOptions: [],
      senderName: "noreply",
      fromAddress: "",
      cc: "",
      bcc: "",
      errorMessage: null,
      ccMenuOpen: false,
      ccAccountManagers: false,
      ccExternalAccountManagers: false,
      fromDomain: "email.domain.com",
      isSubmitting: false,
      isValidating: false,
      isSendingToAllContacts: false,
      validateDisabled: true,
      validationErrors: [],
      showValidateTable: false,
      validateSuccess: false,
      providerIsSendGrid: false,
      providerIsSMTP: false,
      providerIsOutlook: false,
      providerIsConstantContact: false,
      providerIsMailgun: false,
      constantContactAccounts: [],
      constantContactAccountId: null,
      provider: "",
      attachments: [],
      smtpOptions: [],
      smtpAccount: undefined,
      smtp_config_id: undefined,
      selectedTemplates: [],
      emailServices: [],
      footerOptions: [],
      footerId: null,
      signatureOptions: [],
      signatureId: null,
      outlookEmailAliases: [],
      outlookAliasSelected: null,
      mailgunDomainSelected: null,
      users: [],
      userOptions: [],
      entityOptions: [],
      filterReady: false,
      checkFromField: false,
      mailgunDomainOptions: [],
    };
  }

  componentDidMount() {
    // TODO: return with campaign instead of separate query?
    if (this.props.campaignId && this.props.scheduleId) {
      this.fetchSchedule(
        this.props.campaignId,
        this.props.scheduleId,
        this.props.isClone
      ).then(() => {
        this.fetchMailgunDomains();
      }); // don't await
    } else {
      this.fetchMailgunDomains();
    }
    this.fetchTags("contact");
    this.fetchTags("entity");
    this.fetchTags("CampaignTargetCall");
    this.fetchTemplates();
    this.fetchCampaignStatuses(); // don't await
    this.fetchCampaign();
    this.setupTimezones();
    this.fetchEmailServices();
    this.fetchSmtpDetails();
    this.fetchEmailFooters();
    this.fetchEmailSignatures();
    this.fetchOutlookAliases();
    this.fetchUsers();
    this.fetchEntities();
    this.fetchConstantContactAccounts();
  }

  setupTimezones = async () => {
    // TODO: share same code somewhere
    //       maybe just make a timezone select component
    const timeZonesNames = moment.tz.names();
    // remove invalid timezone "US/Pacific-New"
    const usPacificNewIdx = timeZonesNames.indexOf("US/Pacific-New");
    if (usPacificNewIdx !== -1) timeZonesNames.splice(usPacificNewIdx, 1);
    const options = timeZonesNames.map(zone => {
      let offset = moment.tz(zone).format("Z");
      return { text: zone + " (" + offset + ")", key: zone, value: zone };
    });
    this.setState({
      timeZones: options,
    });
  };

  fetchOutlookAliases = async () => {
    const outlookEmailAliases =
      await EmailProviderService.getEmailAliasesForOutlook();
    this.setState({ outlookEmailAliases });
  };

  fetchEmailServices = async () => {
    const emailServices = await EmailProviderService.getEmailProviders(
      true,
      true
    );
    this.setState({ emailServices });
  };

  fetchSmtpDetails = async () => {
    let smtpOptions = await SmtpAccountService.getSmtpAccounts();
    smtpOptions = smtpOptions.map(({ id, return_addr }) => ({
      key: id,
      text: return_addr,
      value: id,
    }));
    this.setState({
      smtpOptions: smtpOptions,
    });
  };

  handleAccountChange = (event, { value }) => {
    if (!value) {
      return;
    }
    const { smtpOptions } = this.state;
    const smtpAccount = smtpOptions
      .filter(opt => opt.value === value)
      .map(({ value, text }) => ({
        id: value,
        return_addr: text,
      }))[0];
    this.setState({
      smtp_config_id: value,
      fromAddress: smtpAccount.return_addr,
    });
  };

  fetchEntities = async (search = null) => {
    const filters = {};
    if (search) {
      filters["search_query"] = search;
    }
    const entities = await EntityService.getEntitiesForFilters(filters);
    let entity_options = entities.map(entity => ({
      text: entity.name,
      key: entity.id,
      value: entity.id,
    }));
    this.setState({ entityOptions: entity_options });
  };

  fetchConstantContactAccounts = async () => {
    const accounts = await ConstantContactAccountsService.getForFilters();
    let accountOptions = accounts.map(account => {
      const authenticated = account.authentication_status === "authenticated";
      return {
        text: account.name + (authenticated ? "" : " (Not Authenticated)"),
        key: account.id,
        value: account.id,
        disabled: !authenticated,
      };
    });
    this.setState({ constantContactAccounts: accountOptions });
  };

  fetchSchedule = async (campaignId, scheduleId, isClone = false) => {
    const schedule = await CampaignScheduleService.getSchedule(
      campaignId,
      scheduleId
    );
    let id = this.props.scheduleId;
    let scheduleName = schedule.schedule_name;
    let isSendingToAllContacts = false;
    if (schedule.conditions.length === 0) {
      isSendingToAllContacts = true;
    }
    if (isClone) {
      id = undefined;
      scheduleName = "";
    }
    if (schedule.info.custom_attachment_uri) {
      this.setState({ validateDisabled: false });
    }
    const providerIsOutlook =
      schedule.email_service === CONSTANTS.EMAIL_SERVICES.OUTLOOK;
    await this.setState({
      scheduleId: id,
      sendNow: schedule.info.send_now,
      emailDeliveryTime: this.getEmailDeliveryTime(
        schedule.email_delivery_time,
        schedule.time_zone
      ),
      timeZone: schedule.time_zone || "UTC",
      senderName: schedule.sender_name || "noreply",
      fromAddress: schedule.info.from_address || "",
      cc: schedule.info.cc || "",
      bcc: schedule.info.bcc || "",
      ccAccountManagers: schedule.info.cc_account_managers || false,
      ccExternalAccountManagers:
        schedule.info.cc_external_account_managers || false,
      fromDomain: "email.domain.com",
      status: schedule.status || false,
      emailTemplateId: schedule.email_template_id || undefined,
      footerId: schedule.info.footer_id,
      signatureId: schedule.info.signature_id,
      conditions: schedule.conditions || [],
      sendToAllEmailAddresses: schedule.info.send_to_all_email_addresses,
      info: schedule.info || {},
      customAttachments: schedule.info.custom_attachments,
      provider: schedule.email_service || "",
      providerIsConstantContact:
        schedule.email_service === CONSTANTS.EMAIL_SERVICES["CONSTANT_CONTACT"],
      scheduleName: scheduleName || "",
      attachments: schedule.attachments || [],
      smtp_config_id: schedule.info.smtp_config_id || undefined,
      providerIsSMTP: schedule.email_service === CONSTANTS.EMAIL_SERVICES.SMTP,
      providerIsOutlook,
      outlookAliasSelected: providerIsOutlook
        ? schedule.info.from_address
        : null,
      isSendingToAllContacts,
      constantContactAccountId:
        schedule.info.constant_contact_account_id || undefined,
    });
    if (schedule.email_service === CONSTANTS.EMAIL_SERVICES.MAILGUN) {
      let [fromAddress, mailgunDomainSelected] =
        schedule.info.from_address.split("@");
      if (!mailgunDomainSelected) {
        mailgunDomainSelected = null;
      }
      await this.setState({ fromAddress, mailgunDomainSelected });
    }
  };

  fetchCampaign = async () => {
    const campaign = await CampaignService.getCampaign(this.props.campaignId);
    this.setState({
      selectedTemplates: campaign.info.selected_templates || [],
    });
  };

  getEmailDeliveryTime(dateTimeStr, timeZone) {
    if (dateTimeStr) {
      return moment
        .utc(dateTimeStr)
        .tz(timeZone || "UTC")
        .utcOffset(0, true);
    } else {
      return moment().utc().startOf("day");
    }
  }

  templateDisabled = template => {
    const { provider, emailTemplateId } = this.state;
    const { provider_list: providerList } = template.info;
    if (!provider) return false;
    if (!providerList) return false;
    let disabled;
    switch (provider) {
      case CONSTANTS.EMAIL_SERVICES.MAILGUN:
      case CONSTANTS.EMAIL_SERVICES.SENDGRID:
        disabled = !providerList.mailgun_sendgrid;
        break;
      case CONSTANTS.EMAIL_SERVICES.CONSTANT_CONTACT:
        disabled = !providerList.constant_contact;
        break;
      case CONSTANTS.EMAIL_SERVICES.SMTP:
        disabled = !providerList.smtp;
        break;
      default:
        disabled = false;
    }
    if (disabled && emailTemplateId === template.id) {
      this.setState({ emailTemplateId: null });
    }

    return disabled;
  };

  setTemplateOptions = async () => {
    const { templates } = this.state;
    let templateOptions = templates
      .filter(t => t.value !== "any")
      .map(template => {
        let disabled = this.templateDisabled(template);
        let description = disabled ? "Disabled for selected provider" : null;

        if (!template.enabled) {
          disabled = true;
          description = "Empty template";
        }

        return {
          ...template,
          disabled,
          description,
        };
      });
    this.setState({
      emailTemplateOptions: templateOptions,
    });
  };

  setUserOptions = async () => {
    const { users } = this.state;
    let userOptions = users.map(user => ({
      key: user.id,
      value: user.id,
      text: user.full_name,
    }));
    this.setState({
      userOptions: userOptions,
    });
  };

  fetchTemplates = async () => {
    let templates = await EmailTemplateService.getEmailTemplatesForFilters();
    templates = templates.map(template => ({
      id: template.id,
      key: template.id,
      value: template.id,
      text: template.name,
      info: template.info,
      enabled: template.enabled,
    }));

    this.setState({ templates }, this.setTemplateOptions);
  };

  fetchCampaignStatuses = async () => {
    let statuses = await CampaignService.getCampaignStatuses();
    let campaignStatusOptions = statuses.map(status => ({
      key: status.id,
      value: status.id,
      text: status.name,
    }));
    campaignStatusOptions = sortBy(campaignStatusOptions, "text");
    campaignStatusOptions.unshift({
      id: null,
      info: null,
      key: null,
      text: "None/Unset",
      value: null,
    });
    this.setState({
      campaignStatusOptions: campaignStatusOptions,
    });
  };

  fetchTags = async model => {
    let tags = await TagService.getTags({ model: model });
    if (model == "contact") {
      this.setState({
        contactTagOptions: tags,
      });
    } else if (model == "CampaignTargetCall") {
      this.setState({
        callTagOptions: tags,
      });
    } else {
      this.setState({
        entityTagOptions: tags,
      });
    }
  };

  fetchEmailFooters = async () => {
    let footers = await EmailFooterService.getEmailFootersForFilters();
    let footerOptions = footers.map(footer => ({
      key: footer.id,
      value: footer.id,
      text: footer.name,
    }));
    footerOptions.unshift({ key: null, value: null, text: "None" });
    this.setState({
      footerOptions: footerOptions,
    });
  };

  fetchMailgunDomains = async () => {
    const domains = await CustomEmailDomainsService.getVerifiedDomains();
    const domainOptions = domains.map(domain => ({
      key: domain.id,
      value: domain.name,
      text: domain.name,
    }));
    if (!this.state.mailgunDomainSelected) {
      const defaultDomain = domains.find(domain => domain.is_default)?.name;
      this.setState({
        mailgunDomainOptions: domainOptions,
        mailgunDomainSelected: defaultDomain,
      });
    } else {
      this.setState({
        mailgunDomainOptions: domainOptions,
      });
    }
  };

  fetchEmailSignatures = async () => {
    let signature = await EmailSignatureService.getEmailSignaturesForFilters();
    let signatureOptions = signature.map(signature => ({
      key: signature.id,
      value: signature.id,
      text: signature.name,
    }));
    signatureOptions.unshift({ key: null, value: null, text: "None" });
    this.setState({
      signatureOptions: signatureOptions,
    });
  };

  fetchUsers = async () => {
    let users = await UserService.getUsers();
    this.setState({ users: users }, this.setUserOptions);
  };

  uploadFiles = async () => {
    if (this.state.files !== undefined && this.state.files.length > 0) {
      await this.uploadManager.uploadFiles(this.state.files);
      return this.uploadManager.uploads.map(f => f.attachment);
    } else {
      return [];
    }
  };

  handleFileProgress = files => {
    this.setState({
      files: files,
    });
  };

  handleFooterChange = async (e, { value }) => {
    let footerId = value;
    if (value == "") {
      footerId = null;
    }
    this.setState({
      footerId: footerId,
    });
  };

  handleSignatureChange = async (e, { value }) => {
    let signatureId = value;
    if (value == "") {
      signatureId = null;
    }
    this.setState({
      signatureId: signatureId,
    });
  };

  handleSelect = (e, { name, value }) => {
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = async () => {
    let {
      sendNow,
      emailDeliveryTime,
      timeZone,
      senderName,
      status,
      scheduleId,
      fromAddress,
      cc,
      bcc,
      ccAccountManagers,
      ccExternalAccountManagers,
      customAttachments,
      emailTemplateId,
      conditions,
      scheduleName,
      provider,
      constantContactAccountId,
      smtp_config_id,
      footerId,
      signatureId,
      isSendingToAllContacts,
      sendToAllEmailAddresses,
      providerIsOutlook,
      providerIsConstantContact,
      outlookAliasSelected,
      mailgunDomainSelected,
      info,
      checkFromField,
    } = this.state;

    // Validates that the email user part (THIS_PART@domain.com) is valid.
    if (checkFromField && !Validate.emailUsername(fromAddress)) {
      this.setState({
        errorMessage: 'Invalid value in "From" field',
      });
    } else {
      this.setState({ isSubmitting: true });
      let attachments = await this.uploadFiles();
      this.setState({
        success: false,
        error: false,
        errorMessage: "",
      });

      if (!emailDeliveryTime) {
        emailDeliveryTime = moment.utc();
        timeZone = "UTC";
      }
      if (provider === CONSTANTS.EMAIL_SERVICES.MAILGUN) {
        fromAddress = `${fromAddress}@${mailgunDomainSelected}`;
      } else if (providerIsOutlook) {
        fromAddress = outlookAliasSelected;
      }

      if (providerIsConstantContact) {
        const selectedAccount = this.state.constantContactAccounts.find(
          account => account.value === constantContactAccountId
        );
        if (!selectedAccount.disabled) {
          fromAddress = selectedAccount.text;
        }
      }

      let data = {
        status: status,
        email_delivery_time: emailDeliveryTime,
        time_zone: timeZone,
        sender_name: senderName,
        info: {
          from_address: fromAddress,
          cc: cc,
          bcc: bcc,
          cc_account_managers: ccAccountManagers,
          cc_external_account_managers: ccExternalAccountManagers,
          custom_attachments: customAttachments,
          send_to_all_email_addresses: sendToAllEmailAddresses,
          smtp_config_id: smtp_config_id,
          constant_contact_account_id: constantContactAccountId,
          footer_id: footerId || null,
          signature_id: signatureId,
          send_now: sendNow,
          schedule_send_option: info["schedule_send_option"],
        },
        email_service: provider,
        schedule_name: scheduleName,
        conditions,
        email_template_id: emailTemplateId,
        attachments: attachments,
        is_sending_to_all_contacts: isSendingToAllContacts,
      };
      if (scheduleId) {
        await CampaignScheduleService.editSchedule(
          this.props.campaignId,
          this.props.scheduleId,
          data
        );
      } else {
        await CampaignScheduleService.createSchedule(
          this.props.campaignId,
          data
        );
      }
      this.props.closeParentModal();
    }
  };

  cleanTemplateConditions() {
    let cleanedCond = this.state.conditions.filter(t => {
      return t != null;
    });
    let conditions = cleanedCond.map(cond => ({
      id: uuidv4(),
      filter_context: cond.filter_context || undefined,
      filter_operand1: cond.filter_operand1 || undefined,
      filter_operator: cond.filter_operator || undefined,
      filter_operand2: cond.filter_operand2 || undefined,
    }));
    return conditions;
  }

  handleDrop = files => {
    if (!this.uploadManager) {
      this.uploadManager = new UploadManager();
      this.uploadManager.addProgressListener(f => this.handleFileProgress(f));
    }
    let copy = this.state.files;
    copy !== undefined ? (copy = [...copy, ...files]) : (copy = [...files]);
    this.setState({
      files: copy,
    });
  };

  deleteFile = fileIndex => {
    let copy = this.state.files;
    delete copy[fileIndex];
    this.setState({
      files: copy,
    });
  };

  handleChange = (event, data) => {
    this.setState(
      {
        [data.name]: data.type === "checkbox" ? data.checked : data.value,
      },
      this.checkIfCanValidate
    );
  };

  handleSendingToAllContactsToggle = (event, data) => {
    this.setState({ [data.name]: data.checked });
    this.setState({ conditions: [] }, this.checkIfCanValidate);
  };

  handleNameChange = event => {
    this.setState({ senderName: event.target.value });
  };

  setEmailDeliveryTime = ({ emailDeliveryTime, timeZone, sendNow }) => {
    if (sendNow !== undefined) {
      emailDeliveryTime = sendNow ? null : this.getEmailDeliveryTime();
      timeZone = "UTC";
    }
    const newState = omitBy(
      { emailDeliveryTime, timeZone, sendNow },
      isUndefined
    );
    this.setState(newState);
  };

  checkAttachmentArray = obj => {
    if (obj.custom_attachment_uri) {
      return true;
    } else {
      return false;
    }
  };

  checkIfCanValidate = () => {
    const isConditionsFilled = this.state.isSendingToAllContacts
      ? true
      : this.state.conditions.length > 0;
    if (
      this.state.emailTemplateId &&
      isConditionsFilled &&
      this.state.customAttachments.every(this.checkAttachmentArray)
    ) {
      this.setState({ validateDisabled: false });
    } else {
      this.setState({ validateDisabled: true });
    }
  };

  handleTemplateSelect = (e, data) => {
    let emailTemplateId = this.state.emailTemplateId;
    emailTemplateId = data.value;
    this.setState({ emailTemplateId }, this.checkIfCanValidate);
  };

  addFilter = () => {
    let conditions = this.state.conditions;
    conditions.push({ id: uuidv4() });
    this.setState({ conditions });
  };

  removeFilter = conditionIdx => {
    let conditions = this.state.conditions;
    if (conditions) {
      this.state.conditions.splice(conditionIdx, 1);
    }
    this.setState({ conditions }, this.checkIfCanValidate);
  };

  addAttachment = () => {
    let customAttachments = this.state.customAttachments;
    customAttachments.push({});
    this.setState({ customAttachments });
  };

  removeAttachment = attachmentIdx => {
    let customAttachments = this.state.customAttachments;
    if (customAttachments) {
      this.state.customAttachments.splice(attachmentIdx, 1);
    }
    this.setState({ customAttachments });
  };

  isReady = () => {
    const {
      scheduleName,
      senderName,
      fromAddress,
      emailTemplateId,
      provider,
      providerIsSMTP,
      providerIsOutlook,
      isSendingToAllContacts,
      sendToAllEmailAddresses,
      outlookAliasSelected,
      filterReady,
    } = this.state;

    if (!isSendingToAllContacts && !filterReady) {
      return false;
    }

    let hasFromAddress = true;
    if (providerIsOutlook) {
      hasFromAddress = outlookAliasSelected !== null;
    }
    if (providerIsSMTP) {
      hasFromAddress = !isEmpty(fromAddress);
    }
    return (
      !!senderName &&
      !!emailTemplateId &&
      hasFromAddress &&
      !!provider &&
      !!scheduleName
    );
  };

  validCondition = condition => {
    if (condition.filter_operand2 === undefined) return false;
    if (condition.filter_operand2 === "") return false;
    return !(
      Array.isArray(condition.filter_operand2) &&
      condition.filter_operand2.length === 0
    );
  };

  isFilterReady = () => {
    const conditions = this.state.conditions;
    let filterReady = true;
    if (conditions.length > 0) {
      filterReady = conditions.every(cond => this.validCondition(cond));
    }
    this.setState({ filterReady });
  };

  makeFilterForm = (condition, conditionIdx) => {
    const {
      campaignStatusOptions,
      templates,
      conditions,
      contactTagOptions,
      entityTagOptions,
      userOptions,
      callTagOptions,
    } = this.state;
    const campaignId = this.props.campaignId;
    return (
      <OutreachFiltersForm
        key={condition.id}
        uuid={condition.id}
        campaignStatuses={campaignStatusOptions}
        emailTemplateOptions={templates}
        userOptions={userOptions}
        campaignId={campaignId}
        conditions_list={conditions}
        condition={condition}
        contactTagOptions={contactTagOptions}
        entityTagOptions={entityTagOptions}
        callTagsOptions={callTagOptions}
        conditionIdx={conditionIdx}
        removeFilterElement={this.removeFilter}
        checkIfCanValidate={this.checkIfCanValidate}
        emailDomainFilter={true}
        fetchEntities={this.fetchEntities}
        isFilterReady={this.isFilterReady}
      />
    );
  };

  handleProviderChange = (event, data) => {
    const isConstantContact =
      data.value === CONSTANTS.EMAIL_SERVICES.CONSTANT_CONTACT;
    const isSMTP = data.value === CONSTANTS.EMAIL_SERVICES.SMTP;
    const isOutlook = data.value === CONSTANTS.EMAIL_SERVICES.OUTLOOK;
    const isConstantContactOrSMTP = isConstantContact || isSMTP;
    this.setState(
      {
        provider: data.value,
        providerIsConstantContact: isConstantContact,
        providerIsSMTP: isSMTP,
        providerIsOutlook: isOutlook,
        fromAddress: "",
        checkFromField: !isConstantContactOrSMTP,
      },
      this.setTemplateOptions
    );
    this.handleAccountChange(null, { value: this.state.smtp_config_id });
  };

  renderAttachments = () => {
    const { attachments, isSubmitting, files, customAttachments } = this.state;
    return (
      <>
        {attachments.length > 0 ? (
          <Form.Field label="Previous Attachments" />
        ) : null}
        <List relaxed>
          {attachments.length > 0
            ? attachments.map((file, i) => {
                return <List.Item key={i}>{file.file_name}</List.Item>;
              })
            : null}
        </List>
        {attachments.length > 0 ? (
          <Form.Field label="New Attachments" />
        ) : (
          <Form.Field label="Attachments" />
        )}
        {isSubmitting && files && files.every(f => f.file) ? (
          <UploadProgressRing uploads={files} />
        ) : (
          <List relaxed>
            {!!files
              ? files.map((file, i) =>
                  file !== undefined ? (
                    <List.Item key={i}>
                      <List.Content floated="right">
                        <Icon
                          name="delete"
                          onClick={() => this.deleteFile(i)}
                        />
                      </List.Content>
                      {file.name}
                    </List.Item>
                  ) : null
                )
              : null}
          </List>
        )}
        <Dropzone onDrop={files => this.handleDrop(files)}>
          {({ getRootProps, getInputProps }) => (
            <Segment style={{ border: "dashed 2px grey", textAlign: "center" }}>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
              </div>
            </Segment>
          )}
        </Dropzone>
        {customAttachments.map((attachment, attachmentIdx) => (
          <CustomAttachmentForm
            key={attachmentIdx}
            attachmentIdx={attachmentIdx}
            attachment={attachment}
            removeAttachment={this.removeAttachment}
            customAttachmentList={this.state.customAttachments}
            scheduleConditions={this.state.conditions}
            emailTemplateId={this.state.emailTemplateId}
            campaignId={this.props.campaignId}
            scheduleId={this.props.scheduleId}
            validateDisabled={this.state.validateDisabled}
            checkIfCanValidate={this.checkIfCanValidate}
            sendToAllEmailAddresses={this.state.sendToAllEmailAddresses}
          />
        ))}
        <Button
          basic
          color="grey"
          size="mini"
          content="Add Custom Attachment"
          type="button"
          onClick={() => this.addAttachment()}
        />
      </>
    );
  };

  render() {
    const {
      scheduleName,
      status,
      sendNow,
      emailDeliveryTime,
      timeZone,
      timeZones,
      senderName,
      cc,
      bcc,
      ccAccountManagers,
      ccExternalAccountManagers,
      fromAddress,
      fromDomain,
      mailgunDomainSelected,
      mailgunDomainOptions,
      conditions,
      emailTemplateOptions,
      emailTemplateId,
      selectedTemplates,
      footerId,
      signatureId,
      provider,
      providerIsConstantContact,
      constantContactAccountId,
      providerIsSMTP,
      providerIsOutlook,
      smtpOptions,
      smtp_config_id,
      isSendingToAllContacts,
      sendToAllEmailAddresses,
      outlookEmailAliases,
      outlookAliasSelected,
      ccMenuOpen,
      info,
      errorMessage,
    } = this.state;
    if (this.props.scheduleId && !provider) {
      return (
        <Icon
          name="circle notched"
          loading
          size="large"
          style={{ display: "block", margin: "0 auto" }}
        />
      );
    }

    const filteredEmailTemplateOptions =
      selectedTemplates && selectedTemplates.length > 0
        ? emailTemplateOptions.filter(option =>
            selectedTemplates.includes(option.value)
          )
        : emailTemplateOptions;

    const ConditionsRender = (
      <>
        <Form.Field label="Conditions" required style={{ marginTop: "10px" }} />
        <div className="and-filter">
          {conditions.map((condition, conditionIdx) => {
            if (emailTemplateOptions.length > 0) {
              return this.makeFilterForm(condition, conditionIdx);
            } else {
              return null;
            }
          })}
        </div>
        <Button
          basic
          color="grey"
          size="mini"
          content="Add Filter"
          name="fromAddress"
          onClick={() => this.addFilter()}
        />
      </>
    );

    let usernameField = (
      <>
        <Form.Input
          label="From"
          placeholder="ex. username"
          onChange={this.handleChange}
          name="fromAddress"
          value={fromAddress}
          error={!!errorMessage}
        />
        <div style={{ paddingTop: "2rem", marginLeft: "-1rem" }}>
          <p>{`@${fromDomain}`}</p>
        </div>
      </>
    );

    if (providerIsSMTP) {
      usernameField = (
        <Form.Select
          selection
          label="Choose Account"
          options={smtpOptions}
          value={smtp_config_id}
          onChange={this.handleAccountChange}
          required
        />
      );
    } else if (provider === CONSTANTS.EMAIL_SERVICES.MAILGUN) {
      usernameField = (
        <>
          <Form.Input
            required
            label="From"
            placeholder="ex. username"
            onChange={this.handleChange}
            name="fromAddress"
            value={fromAddress}
            error={!!errorMessage}
          />
          @
          <Form.Select
            name="mailgunDomainSelected"
            selection
            label="Select a domain"
            options={mailgunDomainOptions}
            onChange={this.handleChange}
            value={mailgunDomainSelected}
            required
          />
        </>
      );
    } else if (providerIsOutlook) {
      usernameField = (
        <Form.Select
          name="outlookAliasSelected"
          selection
          label="Select an alias"
          options={outlookEmailAliases}
          onChange={this.handleChange}
          value={outlookAliasSelected}
          required
        />
      );
    }
    const ready = this.isReady();

    return (
      <>
        <RuvixxForm
          ready={ready}
          onSubmit={this.handleSubmit}
          onSuccess={!errorMessage ? this.props.onSuccess : () => {}}
          onCancel={!errorMessage ? this.props.onClose : () => {}}
          error={!!errorMessage}
          errorMessage={errorMessage}
          extraButtons={[
            <EmailableContactsModal
              disabled={!ready}
              campaignId={this.props.campaignId}
              data={{
                conditions: this.state.conditions,
                email_template_id: this.state.emailTemplateId,
                sending_to_all_contacts: this.state.isSendingToAllContacts,
                info: {
                  send_to_all_email_addresses:
                    this.state.sendToAllEmailAddresses,
                },
              }}
            />,
          ]}
          successHeader="Email Schedule Created"
          successMessage="Successfully created a new schedule"
        >
          {!providerIsConstantContact && (
            <Form.Checkbox
              toggle
              name="status"
              checked={status}
              onChange={this.handleChange}
              label={`Status [${status ? "Active" : "Inactive"}]`}
              required
            />
          )}
          <Form.Input
            name="scheduleName"
            value={scheduleName}
            onChange={this.handleChange}
            label="Schedule Name"
            required
          />
          <Form.Group>
            <Form.Select
              selection
              label="Email Provider"
              placeholder="Select an Email Provider"
              options={this.state.emailServices}
              onChange={this.handleProviderChange}
              value={provider}
              required
            />
            {providerIsConstantContact && (
              <Form.Select
                selection
                name={"constantContactAccountId"}
                label="Constant Contact Account"
                placeholder="Select Account"
                options={this.state.constantContactAccounts}
                onChange={this.handleSelect}
                value={constantContactAccountId}
                required
              />
            )}
          </Form.Group>
          {!providerIsConstantContact && (
            <>
              <Form.Group widths="equal">
                <Form.Input
                  required
                  label="Sender Name"
                  placeholder="Type in Sender Name"
                  onChange={this.handleNameChange}
                  name="senderName"
                  value={senderName}
                />
                {usernameField}
              </Form.Group>
              <Accordion active={ccMenuOpen} as={Form.Field}>
                <Accordion.Title
                  active={ccMenuOpen}
                  index={0}
                  onClick={() => this.setState({ ccMenuOpen: !ccMenuOpen })}
                >
                  <Icon name="dropdown" />
                  CC/BCC Options
                </Accordion.Title>
                <Accordion.Content active={ccMenuOpen}>
                  <Form.Group>
                    <Form.Checkbox
                      toggle
                      name="ccAccountManagers"
                      checked={ccAccountManagers}
                      onChange={this.handleChange}
                      label="CC Account Managers"
                    />
                    <Form.Checkbox
                      toggle
                      name="ccExternalAccountManagers"
                      checked={ccExternalAccountManagers}
                      onChange={this.handleChange}
                      label="CC External Account Managers"
                    />
                  </Form.Group>
                  <Form.Input
                    label="CC"
                    placeholder="email1@test.com,email2@test.com"
                    onChange={this.handleChange}
                    name="cc"
                    value={cc}
                  />
                  <Form.Input
                    label="BCC"
                    placeholder="email1@test.com,email2@test.com"
                    onChange={this.handleChange}
                    name="bcc"
                    value={bcc}
                  />
                </Accordion.Content>
              </Accordion>
            </>
          )}
          {!providerIsConstantContact && (
            <Grid
              columns="equal"
              style={{ paddingLeft: "0.5em", paddingBottom: "2em" }}
            >
              <Grid.Row>
                <Grid.Column>
                  <EmailDeliveryTime
                    timeZone={timeZone}
                    timeZones={timeZones}
                    sendNow={sendNow}
                    emailDeliveryTime={emailDeliveryTime}
                    setEmailDeliveryTime={this.setEmailDeliveryTime}
                  />
                </Grid.Column>
                <Grid.Column>
                  <EmailScheduleSendOptionForm
                    timeZone={timeZone}
                    timeZones={timeZones}
                    sendNow={sendNow}
                    emailDeliveryTime={emailDeliveryTime}
                    info={info}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
          <Form.Group widths="equal">
            <Form.Select
              required
              search
              options={filteredEmailTemplateOptions}
              name="email_template_id"
              placeholder="Select an Email Template"
              value={emailTemplateId}
              label="Email Template"
              onChange={this.handleTemplateSelect}
            />
            <Form.Select
              search
              clearable
              label="Email Footer"
              placeholder="Select an Email Footer"
              value={footerId}
              onChange={this.handleFooterChange}
              options={this.state.footerOptions}
            />
            <Form.Select
              search
              clearable
              label="Email Signature"
              placeholder="Select an Email Signature"
              value={signatureId}
              onChange={this.handleSignatureChange}
              options={this.state.signatureOptions}
            />
          </Form.Group>
          {!providerIsConstantContact && this.renderAttachments()}
          <Form.Checkbox
            toggle
            name="sendToAllEmailAddresses"
            checked={sendToAllEmailAddresses}
            onChange={this.handleChange}
            label={`Send to all enabled emails addresses per contact [${
              sendToAllEmailAddresses ? "Active" : "Inactive"
            }]`}
            required
          />
          <Form.Checkbox
            toggle
            name="isSendingToAllContacts"
            checked={isSendingToAllContacts}
            onChange={this.handleSendingToAllContactsToggle}
            label={`Send to all contacts [${
              isSendingToAllContacts ? "Active" : "Inactive"
            }]`}
            required
          />
          {!isSendingToAllContacts ? ConditionsRender : null}
        </RuvixxForm>
      </>
    );
  }
}

export default CampaignScheduleForm;
